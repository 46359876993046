import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import Counter from "../../components/Counter";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function TrademarkAssignment() {
  /* Slider */
  // const assISlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Trademark Assignment Service`,
  //     content: `Best Trademark Assignment Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online Trademark Assignment Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trademark Assignment`,
    buyBtnLink: `#pricing-buy`,
    price: `1999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Trademark
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `10% discount on 4+ 
          applications`,
        icon: "/imgs/icons/it/80u.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const assIAboutData = {
    header: `Online Trademark Assignment`,
    sub_title: `One of the top most trademark assignment service in Hosur`,
    content_paragraph: [
      `In order to transfer ownership of a well-known phrase, slogan, symbol, design, or trademark from one party
       to another, a formal written agreement known as a trademark assignment is used. The future owner is referred
        to as "the Assignee," whereas the current owner is referred to as "the Assignor" for that specific trademark.
        Because it enables end consumers to instantly associate certain attributes with a well-known brand, a 
        trademark is a cherished possession or valuable asset.`,

      // `Identified by words, phrases, symbols, designs, or trademarks 
      // associated to the business as part of the transfer of ownership of rights, titles, and interests 
      // from one party to another in India.`
    ],
  };
  /* Scroll Nav Data */
  const assIScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#checklist`,
      heading: `Checklist`,
    },

    {
      id: `#documentss`,
      heading: `Documents`,
    },

    {
      id: `#types`,
      heading: `Types`,
    },

  ];
  /* Content Form Component Data */
  const assICfData = {
    id: "checklist",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Trademark Assignment Agreement Checklist",
    content: `The trademark examiner looks into the trademark application to make sure it complies with all applicable trademark laws. 
  An objection to a trademark application may arise for any of the following reasons.`,
    body: [
      {
        head: `Trademark`,
        points: `If the trademark has been registered with the U.S. Patent and Trademark Office, it identifies the legally distinctive word, phrase, symbol, or design and includes the official trademark number.`,
        icon: true,
      },
      {
        head: `Effective date:`,
        points: `The recognized trademark is formally transferred to the mark owner on the effective date.`,
        icon: true,
      },
      {
        head: `Assignor:`,
        points: `The trademark's present owner is referred.`,
        icon: true,
      },
      {
        head: `Assignee:`,
        points: `The person paying money to obtain or acquire ownership of a trademark.`,
        icon: true,
      },
      {
        head: `Warranties:`,
        points: ` The assignor guarantees that they are the legitimate owners at the time of the assignment, have the authority to transfer ownership, and have the right to transfer the trademark.`,
        icon: true,
      },
      {
        head: `Signatures:`,
        points: `Indicating that a contract or agreement between the assignor and the assignee is required.`,
        icon: true,
      },
    ],
  };
  /* Img Content Component Data */
  const assIIcData = {
    id: "procedure",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Trademark Assignment Procedure",
    image: "/imgs/registration/ipr/td5.png",
    alt_tag: "trademark assignment Procedure",
    points: [
      {
        head: "Unregistered Trademark Assignment:",
        content: `In accordance with Section 39, the unregistered trademark may be transferred or authorized 
        with or without the goodwill of the relevant business concern.`,
        icon: true,
      },
      {
        head: "Registered Trademark Assignment:",
        content: `May be transferred in whole or in part, either with or without the business concern's goodwill,
         to another organization. Depending on the specifics of the assignment, different fees apply for trademark assignments.`,
        icon: true,
      },
      {
        head: "Need for Trademark Assignment Agreement",
        content: `It is a legally binding document that aids in transferring trademark or service mark 
        ownership as needed. When a business or product is sold to another company or person, a trademark 
        transfer or ownership becomes a factor in the case.`,
        icon: true,
      },
    ],
  };
  /* ImgSlider Data */
  const objImages = [
    "/imgs/assited/invoice-billing.png",
    "/imgs/assited/observation.png",
    "/imgs/assited/ndh.png",
  ];

  /*  Slider Content Component Data */
  const assISCData = {
    id: "documentss",
    background: [],
    mt_div: "mt-3",
    header: "Documents Needed For Trademark Assignment",
    content: [
      {
        points: `Along with form TM-P, the following papers must be delivered to the trademark registrar.`,
        icon: true,
      },
      {
        points: `The assignor's NOC.`,
        icon: true,
      },
      {
        points: `Certificate for a trademark.`,
        icon: true,
      },
      {
        points: `Agreement for a trademark assignment.`,
        icon: true,
      },
      {
        points: `Documentation proving the assignor's and assignee's identities.`,
        icon: true,
      },
    ],
    images: objImages,
  };
  var FAQ_data = [
    {
      header: "What is the purpose of the Trademark Assignment?",
      body: [
        {
          content: `Assignment of a trademark occurs whenever the right to use it in
          that capacity is passed from one group to the next, whether with or
          without the business's consent.`,
          icon: false,
        },
      ],
    },
    {
      header: " Can an unregistered trademark be assigned? ",
      body: [
        {
          content: `An unregistered trademark assignment can be done with or without the goodwill of the business
           concerned. For the same, a request has to be made on Form TM-16 for an unregistered trademark to be assigned.`,
          icon: false,
        },
      ],
    },
    {
      header: " Can an Individual Assign a Trademark? ",
      body: [
        {
          content: `Yes, an individual can assign a registered trademark.`,
          icon: false,
        },
      ],
    },
    {
      header:
        " Why it is essential to get a Trademark Assignment Agreement in India? ",
      body: [
        {
          content: `It is a lawful means that helps in assigning ownership or a trademark or service mark when needed.
           A Trademark assignment comes into the role when a product or company is being sold or purchased by another 
           person or company.`,
          icon: false,
        },
      ],
    },
    {
      header: " How can a person know if something is trademarked? ",
      body: [
        {
          content: `A person can start by searching for government-registered trademarks. For the same person, you can 
          use the trademark database on USPTO's website free of cost.`,
          icon: false,
        },
      ],
    },
    {
      header: " Do trademark assignments need to be notarized? ",
      body: [
        {
          content: `The party assigning the trademark must sign the assignment, and while notarization is strongly 
          put forward it is not strictly required. Once an assignment has been executed, it should be recorded.`,
          icon: false,
        },
      ],
    },
    {
      header: " What are the kinds of Trademark Assignment Agreements? ",
      body: [
        {
          content: `Assignment of Intellectual Property Rights Agreement`,
          icon: true,
        },
        {
          content: `Assignment and Transfer Agreement`,
          icon: true,
        },
        {
          content: `Intellectual Property Assignment`,
          icon: true,
        },
        {
          content: `Transfer of Trademark Rights`,
          icon: true,
        },
        {
          content: `Trademark Purchase and Trademark Assignment Agreement`,
          icon: true,
        },
      ],
    },

    {
      header:
        "How do you tell if a company, commodity, or service has a trademark?",
      body: [
        {
          content: `Federally registered trademarks are searchable. Additionally, you
          can check the USPTO website's free trademark database to see if a
          company, good, or service is already protected by a trademark.`,
          icon: false,
        },
      ],
    },
    {
      header: "Where does trademark law come from?",
      body: [
        {
          content: `The trademarks act of 1999 is a national statute, and there are also
          laws created by international multilateral conventions, regional
          treaties, bilateral national agreements, and judicial rulings, among
          other things.`,
          icon: false,
        },
      ],
    },
    {
      header: " What is the Trademark Assignment's required form?",
      body: [
        {
          content: `Form TM-24 can be used to submit an assignment request for a
          trademark. The assignor and assignee may submit a request for the
          joint Trademark Assignment using Form TM-2.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Trademark Assignment'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={assISlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={assIAboutData} />
          <Counter />
          <ScrollNav scroll_data={assIScrollId} />
          <ImgContent item={assIIcData} />
          <ContentForm CFSection_data={assICfData} />

          <SliderContent ScSection_data={assISCData} />
          <section className='main-content'>
            <div className='container mt-5'>
              <div className='row mt-5'>
                <div className='col-lg-12 col-md-12 col-sm-12 content'>
                  <div className='desc ipr'>
                    <section id='types' className=' mb-0'>
                      <div>
                        <header
                          className='section-header'
                          style={{ marginTop: '110px' }}
                        >
                          <h2>Trademark Assignment Agreement</h2>
                        </header>
                        <p>
                          The following information should be included in the
                          trademark assignment agreement:
                        </p>
                        <div className='row'>
                          <div className='col-md-6'>
                            <h5 className='mb-3 mt-3'>
                              Trademark Assignment Types
                            </h5>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Agreement on assignment and transfer.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Agreement for the assignment of intellectual
                              property rights.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Right transferring to marks.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Assignment of intellectual property.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Agreement for the purchase and assignment of
                              trademarks.
                            </p>
                          </div>
                          <div className='col-md-6'>
                            <h5 className='mb-3 mt-3'>
                              Things to include in Trademark Assignment
                            </h5>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              Names of the trademark's previous and new owners.
                            </p>

                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              If there is any commotion, this is where any
                              issues will be resolved.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              The justification for the Assignor's ownership of
                              the transfer rights to the trademark and
                              associated goodwill.
                            </p>
                            <p>
                              <i className='bi bi-check-circle-fill'></i>
                              When the registered trademark is officially
                              transferred to the new owner.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                {/* form sticky */}
                {/* <SideBar /> */}
              </div>
            </div>
          </section>

          <Guidance />
          <Cta />
          <Testimonial />

          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
